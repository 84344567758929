import { ErrorPage } from 'src/components/ErrorPage/ErrorPage'
import { LandingCases } from 'src/containers/LandingCases'
import { Layout } from 'src/components/Layout/Layout'
import { PageProps } from 'gatsby'
import React from 'react'

const AboutPage: React.FC<PageProps<{}>> = () => {
  return (
    <Layout title="Page Not Found | RabbitPeepers">
      <ErrorPage message="404 | Page Not Found" />
      <LandingCases />
    </Layout>
  )
}

export default AboutPage
